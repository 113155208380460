import Check from "@mui/icons-material/Check";
import ChevronRight from "@mui/icons-material/ChevronRight";
import IndeterminateCheckBox from "@mui/icons-material/IndeterminateCheckBox";
import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";
import cx from "classnames";
import type { FC, ForwardRefRenderFunction, ReactNode, RefAttributes } from "react";
import { forwardRef } from "react";

import { BasicTooltip } from "../Tooltip";

const DropdownMenuRoot: FC<RadixDropdownMenu.DropdownMenuProps> = ({
	children,
	modal: modalRaw,
	...props
}) => {
	const modal = modalRaw === undefined ? false : modalRaw;
	return (
		<RadixDropdownMenu.Root modal={modal} {...props}>
			{children}
		</RadixDropdownMenu.Root>
	);
};

const DropdownMenuTrigger: ForwardRefRenderFunction<
	HTMLButtonElement,
	RadixDropdownMenu.DropdownMenuTriggerProps & RefAttributes<HTMLButtonElement>
> = ({ children, ...props }, ref) => {
	return (
		<RadixDropdownMenu.Trigger ref={ref} {...props}>
			{children}
		</RadixDropdownMenu.Trigger>
	);
};

const DropdownMenuSubTrigger: ForwardRefRenderFunction<
	HTMLDivElement,
	RadixDropdownMenu.DropdownMenuSubTriggerProps &
		RefAttributes<HTMLDivElement> & { disabledMessage?: ReactNode }
> = ({ children, ...props }, ref) => {
	const item = (
		<RadixDropdownMenu.SubTrigger
			{...props}
			ref={ref}
			className="DropdownMenuSubTrigger flex cursor-default flex-row items-center px-2 py-1.5 mx-1 rounded !transition-none"
		>
			<div className="mr-2 flex flex-row items-center space-x-2">{children}</div>
			<ChevronRight className="ml-auto" />
		</RadixDropdownMenu.SubTrigger>
	);

	if (props.disabled && props.disabledMessage) {
		return (
			<BasicTooltip
				content={<div className="max-w-[16rem]">{props.disabledMessage}</div>}
				contentProps={{ side: "right" }}
			>
				<button className="flex w-full cursor-default flex-col items-stretch">
					{item}
				</button>
			</BasicTooltip>
		);
	}

	return item;
};

const DropdownMenuContent: ForwardRefRenderFunction<
	HTMLDivElement,
	RadixDropdownMenu.DropdownMenuContentProps & RefAttributes<HTMLDivElement>
> = ({ children, ...props }, ref) => {
	return (
		<RadixDropdownMenu.Content
			{...props}
			ref={ref}
			className={cx(
				"data-[side=top]:animate-slideUpAndFade",
				"data-[side=right]:animate-slideLeftAndFade",
				"data-[side=bottom]:animate-slideDownAndFade",
				"data-[side=left]:animate-slideRightAndFade",
				"data-[state=open]:animate-in",
				"data-[state=closed]:animate-out",
				"rounded-md",
				"shadow-xl",
				"z-popover",
				props.className
			)}
			sideOffset={4}
		>
			<div
				className={cx(
					"overflow-hidden",
					"rounded-md",
					"border",
					"border-secondary",
					"bg-primary-raised",
					"text-sm"
				)}
			>
				<div className="DropdownMenuContentScrollContainer max-h-[32rem] overflow-y-auto py-1">
					{children}
				</div>
			</div>
		</RadixDropdownMenu.Content>
	);
};

const DropdownMenuLabel: ForwardRefRenderFunction<
	HTMLDivElement,
	RadixDropdownMenu.DropdownMenuLabelProps & React.HTMLAttributes<HTMLDivElement>
> = ({ children, ...props }, ref) => {
	return (
		<RadixDropdownMenu.Label
			{...props}
			ref={ref}
			className={cx(
				"DropdownMenuItem",
				"font-bold",
				"text-tertiary",
				"text-xs",
				"tracking-wider",
				`flex cursor-pointer flex-row items-center space-x-2 px-2 py-1.5 mx-1 rounded`
			)}
		>
			{children}
		</RadixDropdownMenu.Label>
	);
};

const DropdownMenuItem: ForwardRefRenderFunction<
	HTMLDivElement,
	RadixDropdownMenu.DropdownMenuItemProps &
		RefAttributes<HTMLDivElement> & {
			variant?: "default" | "negative";
			disabledMessage?: ReactNode;
		}
> = ({ children, variant, className, ...props }, ref) => {
	let variantClass: string;
	switch (variant) {
		case "negative":
			variantClass = "DropdownMenuItem DropdownMenuItem--negative";
			break;

		default:
			variantClass = "DropdownMenuItem DropdownMenuItem--default";
			break;
	}

	const item = (
		<RadixDropdownMenu.Item
			{...props}
			ref={ref}
			className={cx(
				// We are now using a blanket `transition-all`, but it is not very performant on
				// the radix dropdown menu and will sometimes look very clunky. So I'm opting out
				// for this component.
				"!transition-none",
				variantClass,
				className,
				"group flex cursor-pointer flex-row items-center space-x-2 px-2 py-1.5 mx-1 rounded"
			)}
		>
			{children}
		</RadixDropdownMenu.Item>
	);

	if (props.disabled && props.disabledMessage) {
		return (
			<BasicTooltip
				content={<div className="max-w-[16rem]">{props.disabledMessage}</div>}
				contentProps={{ side: "right" }}
			>
				<button className="w-full cursor-default">{item}</button>
			</BasicTooltip>
		);
	}

	return item;
};

const DropdownMenuSubContent: ForwardRefRenderFunction<
	HTMLDivElement,
	RadixDropdownMenu.DropdownMenuSubContentProps & RefAttributes<HTMLDivElement>
> = ({ children, sideOffset = 4, ...props }, ref) => {
	return (
		<RadixDropdownMenu.SubContent
			{...props}
			ref={ref}
			className={cx(
				"data-[side=bottom]:animate-slideDownAndFade",
				"data-[side=left]:animate-slideRightAndFade",
				"data-[side=right]:animate-slideLeftAndFade",
				"data-[side=top]:animate-slideUpAndFade",
				props.className,
				"-mt-1",
				"max-w-xs",
				"overflow-hidden",
				"rounded",
				"border",
				"py-1",
				"text-sm",
				"shadow-lg",
				"z-popover",
				"bg-primary-raised"
			)}
			sideOffset={sideOffset}
		>
			{children}
		</RadixDropdownMenu.SubContent>
	);
};

const DropdownMenuSeparator: ForwardRefRenderFunction<
	HTMLDivElement,
	RadixDropdownMenu.DropdownMenuSeparatorProps & RefAttributes<HTMLDivElement>
> = (_, ref) => {
	return <RadixDropdownMenu.Separator ref={ref} className="my-1 border-t" />;
};

const DropdownMenuFooter: FC<{ children: ReactNode }> = ({ children }) => {
	return (
		<>
			{/* `-mb-2` is required here to show up at the bottom, countering the `py-2` in the parent */}
			<RadixDropdownMenu.Label className="-mb-1.5 mt-2 border-t bg-tertiary px-2 py-1.5 mx-1 rounded italic text-secondary">
				{children}
			</RadixDropdownMenu.Label>
		</>
	);
};

const DropdownCheckboxItem: ForwardRefRenderFunction<
	HTMLDivElement,
	RadixDropdownMenu.DropdownMenuCheckboxItemProps & RefAttributes<HTMLDivElement>
> = ({ className, checked, children, ...props }, ref) => {
	let checkMark = <Check className="opacity-0" />;
	if (checked === "indeterminate") {
		checkMark = <IndeterminateCheckBox className="text-inverse-primary" />;
	} else if (checked) {
		checkMark = <Check className="absolute left-0 top-0 h-full w-full text-inverse-primary" />;
	}

	return (
		<RadixDropdownMenu.CheckboxItem
			{...props}
			ref={ref}
			checked={checked}
			className={cx(
				className,
				"DropdownMenuCheckboxItem group flex cursor-pointer flex-row items-center space-x-2 px-2 py-1.5 mx-1 rounded !transition-none"
			)}
		>
			<div className="CheckboxItemMark relative h-4 w-4">{checkMark}</div>
			{children}
		</RadixDropdownMenu.CheckboxItem>
	);
};

const DropdownRadioItem: ForwardRefRenderFunction<
	HTMLDivElement,
	RadixDropdownMenu.DropdownMenuRadioItemProps &
		RefAttributes<HTMLDivElement> & { level?: "primary" | "secondary" }
> = ({ className, children, ...props }, ref) => {
	return (
		<RadixDropdownMenu.RadioItem
			{...props}
			ref={ref}
			className={cx(
				className,
				props.level === "secondary" && "italic text-secondary",
				// We are now using a blanket `transition-all`, but it is not very performant on
				// the radix dropdown menu and will sometimes look very clunky. So I'm opting out
				// for this component.
				"!transition-none",
				"DropdownMenuRadioItem flex cursor-pointer flex-row items-center px-2 py-1.5 mx-1 rounded"
			)}
		>
			<div className="mr-2 h-4 w-4">
				<DropdownMenu.ItemIndicator className="RadioItemMark">
					<Check />
				</DropdownMenu.ItemIndicator>
			</div>
			{children}
		</RadixDropdownMenu.RadioItem>
	);
};

/**
 * Styled variant of the Radix `DropdownMenu` component.
 */
export const DropdownMenu = {
	Root: DropdownMenuRoot,

	Trigger: forwardRef(DropdownMenuTrigger),

	Portal: RadixDropdownMenu.Portal,

	Content: forwardRef(DropdownMenuContent),

	Label: forwardRef(DropdownMenuLabel),
	Item: forwardRef(DropdownMenuItem),

	Group: RadixDropdownMenu.Group,

	CheckboxItem: forwardRef(DropdownCheckboxItem),

	RadioGroup: RadixDropdownMenu.RadioGroup,
	RadioItem: forwardRef(DropdownRadioItem),
	ItemIndicator: RadixDropdownMenu.ItemIndicator,

	Sub: RadixDropdownMenu.Sub,
	SubTrigger: forwardRef(DropdownMenuSubTrigger),
	SubContent: forwardRef(DropdownMenuSubContent),

	Separator: forwardRef(DropdownMenuSeparator),

	Arrow: RadixDropdownMenu.Arrow,

	Footer: DropdownMenuFooter,
};
