import { Help as HelpIcon } from "@mui/icons-material";

import { AppTopBarButton } from "@moment/design-system/AppTopBarButton";
import { AppTopBarItem } from "@moment/design-system/AppTopBarItem";

/**
 * <HelpButton/>
 * Help Button for top bar
 */
export const HelpButton = () => {
	return (
		<AppTopBarItem>
			<a
				href="https://www.moment.dev/docs?utm_source=moment-app&utm_medium=app-topbar&utm_campaign=help-button"
				target="_blank" // Open the link in the user's browser
				rel="noopener noreferrer"
				className="no-underline"
			>
				<AppTopBarButton>
					<HelpIcon className="text-palette-gray-9" /> Help
				</AppTopBarButton>
			</a>
		</AppTopBarItem>
	);
};
