import { Login as LoginIcon } from "@mui/icons-material";
import { useRouter } from "next/router";
import { useCallback, useState } from "react";

import { AppTopBarButton } from "@moment/design-system/AppTopBarButton";
import { AppTopBarItem } from "@moment/design-system/AppTopBarItem";

import { useAuth } from "~/auth/useAuth";

export const useDoLogin = () => {
	const router = useRouter();
	const { loginWithRedirect } = useAuth();

	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const doLogin = useCallback(async () => {
		if (isButtonDisabled) {
			return;
		}
		setIsButtonDisabled(true);
		const timer = setTimeout(() => setIsButtonDisabled(false), 2000);
		await loginWithRedirect({ redirectPath: router.asPath });

		return () => clearTimeout(timer);
	}, [isButtonDisabled, loginWithRedirect, router.asPath]);

	return [doLogin, isButtonDisabled] as const;
};

/**
 * <LoginButton/>
 * Login Button for top bar
 */
export const LoginButton = () => {
	const [doLogin, loginDisabled] = useDoLogin();

	return (
		<AppTopBarItem>
			<AppTopBarButton disabled={loginDisabled} onClick={doLogin}>
				<LoginIcon />
				Login
			</AppTopBarButton>
		</AppTopBarItem>
	);
};
